<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="user.fields">
        <v-col cols="12" lg="6" md="8" offset-lg="3" offset-md="2" offset-sm="1" sm="10">
          <v-subheader class="pa-0">Gebruiker</v-subheader>
          <v-card>
            <v-card-text>
              <KTextField v-model="values.name" field="name" required/>
              <KTextField v-model="values.email" field="email" required/>
              <KCheckbox v-model="values.isAdmin" field="isAdmin"/>
              <RolesAutocomplete v-model="values.roles" field="roles" hide-details multiple/>
              <KRadioGroup v-model="values.dashboardType"
                           :items="dashboardTypes"
                           :style="{minWidth: '240px'}"
                           column
                           :label="$t('portal.portalType.title')"
                           grid="col-sm-6 pr-2 mb-6"
                           hide-details/>
              <StartpageAutocomplete v-if="values.dashboardType === START_PAGE"
                                     v-model="values.dashboard"
                                     field="startPage"
                                     hide-details
                                     required/>
              <KTextField v-else-if="values.dashboardType === DIRECT_URL"
                          v-model="values.startUrl"
                          placeholder="https://www."
                          :label="$t('portal.fields.directUrl')"
                          required
                          :rules="[isURL]"/>

              <KTextField v-model="values.favoriteUrl"
                          placeholder="https://www."
                          :label="$t('portal.fields.favoriteUrl')"
                          :rules="[isOptionalURL]"/>
            </v-card-text>
          </v-card>
        </v-col>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import StartpageAutocomplete from '@/components/autocompletes/StartpageAutocomplete.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import Form from '@/components/vuetifyResource/VuetifyResourceBaseForm.vue';
import User from '@/application/models/admin/user.js';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import RolesAutocomplete from '@/components/autocompletes/RolesAutocomplete.vue';
import isURL from 'validator/es/lib/isURL';
import dashboardTypes from '@/application/types/dashboard.json';

export default {
  name: 'UserForm',
  extends: Form,
  components: {
    StartpageAutocomplete,
    KRadioGroup,
    RolesAutocomplete,
    KCheckbox,
    KTextField,
    KFieldGroup,
  },
  data: () => ({
    values: new User(),
    ...dashboardTypes,
  }),
  computed: {
    dashboardTypes() {
      return [
        {
          text: this.$t('dashboard.dashboardType.DEFAULT'),
          value: dashboardTypes.DEFAULT,
        },
        {
          text: this.$t('dashboard.dashboardType.DIRECT_URL'),
          value: dashboardTypes.DIRECT_URL,
        },
        {
          text: this.$t('dashboard.dashboardType.START_PAGE'),
          value: dashboardTypes.START_PAGE,
        },
      ];
    },
  },
  methods: {
    isURL(value) {
      return (!!value && isURL(value, {
        require_protocol: true,
        protocols: ['http', 'https'],
      })) || this.$t('validation.url');
    },
    isOptionalURL(value) {
      if(value){
        return this.isURL(value);
      }
    },
  },
};
</script>
